import React, { ReactNode } from 'react';

interface SectionProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  className?: string;
}

export const Section = ({ title, subtitle, children, className }: SectionProps) => {
  return (
    <div className={`relative bg-slate-900 py-16 sm:py-24 lg:py-32 ${className || ''}`}>
      <div className="relative">
        <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <p className="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
            {title}
          </p>
          <p className="mt-5 mx-auto max-w-prose text-xl text-gray-300">{subtitle}</p>
        </div>
        {children}
      </div>
    </div>
  );
};
