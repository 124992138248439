import React from 'react';
import { IPost } from '../../../dto/IPost';
import { PostCard } from '../components/PostCard';

export const Guides = ({ posts }: { posts: IPost[] }) => {
  return (
    <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
      {posts?.map((post) => (
        <PostCard post={post} />
      ))}
    </div>
  );
};
