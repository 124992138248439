import React from 'react';
import { graphql } from 'gatsby';
import { Guides } from './sections/Guides';
import { Separator } from '../../components/Separator';
import { IPostNode } from '../../dto/IPost';
import { convertPostNodeToPost } from '../../utils';
import { Layout } from '../../components/Layout';
import { ConnectionLine } from '../../components/ConnectionLine';
import { Latest } from './sections/Latest';
import { Section } from './components/Section';
import { AboutMe } from '../../components/AboutMe';
import { StaticImage } from 'gatsby-plugin-image';
import { useSignup } from '../../hooks/useSignup';

const feedback = [
  'The whole blog was helpful to understand the memory management concept.',
  'Things described in a very simple way. Good work, keep it up.',
  'Clear, concise explanation. I like how you kept the examples simple to make your point.',
];

const HeroSignup = () => {
  const { email, success, loading, setEmail, handleSubmit } = useSignup();
  return (
    <div className="lg:py-24 z-10">
      <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6">
        <span>React and JavaScript tutorials </span>
        <span className="text-blue-500">delivered to your inbox</span>
      </h1>
      <p className="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
        Join over 1,000 developers who receive my tutorials via email.
      </p>
      <div className="mt-10 sm:mt-12">
        <form onSubmit={handleSubmit} className="sm:max-w-xl sm:mx-auto lg:mx-0">
          {success ? (
            <div className="mt-6 text-center">
              <p className="text-lg leading-6 text-gray-300">Thanks for subscribing!</p>
              <p className="mt-3 text-md leading-6 text-gray-400">
                Please confirm your email address by clicking the link in the email I sent you.
              </p>
            </div>
          ) : (
            <>
              <div className="sm:flex">
                <div className="min-w-0 flex-1">
                  <label htmlFor="email" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Email"
                    className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="block w-full py-3 px-4 rounded-md shadow bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
                  >
                    {loading ? 'Loading...' : 'Subscribe'}
                  </button>
                </div>
              </div>
              <p className="mt-3 text-sm text-gray-400 sm:mt-4">
                No spam. Unsubscribe at any time.
              </p>
            </>
          )}
          <div className="mt-6 md:mt-24 lg:mt-52 mb-0 lg:-mb-32">
            <p className="text-sm text-slate-600 mb-4">WHAT OTHERS ARE SAYING</p>
            <div className="flex flex-wrap sm:flex-nowrap gap-4">
              {feedback.map((item, index) => (
                <div
                  key={`feedback-${index}`}
                  className="px-4 py-6 ring-2 ring-indigo-400/70 text-slate-400 relative z-10 text-sm bg-slate-700 rounded-lg transition duration-300 hover:-translate-y-5"
                >
                  <p className="z-10 inline-block">{item}</p>
                  <div className="text-9xl text-slate-800/30 absolute left-0 -top-2 -z-10">"</div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const HeroImage = () => {
  return (
    <div className="ml-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0">
      <div>
        <StaticImage
          className="hidden w-full z-10 py-10 lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none lg:block transition-opacity ease-in-out delay-1000"
          src="../../../static/logos/rocket.png"
          alt="Rocket Illustration"
          placeholder="blurred"
          objectFit="contain"
          width={600}
        />
      </div>
      <svg
        className="hidden lg:block absolute z-0 transform md:-translate-y-1/2 lg:-translate-x-2 right-0 top-0"
        width={404}
        height={784}
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect x={0} y={0} width={4} height={4} className="text-gray-500" fill="currentColor" />
          </pattern>
        </defs>
        <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
      </svg>
    </div>
  );
};

export default function MainPage({
  data,
}: {
  data: { guides: { nodes: IPostNode[] }; latestPosts: { nodes: IPostNode[] } };
}) {
  const guides = data?.guides?.nodes;
  const latestPosts = data?.latestPosts?.nodes;

  return (
    <Layout>
      <main>
        <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <HeroSignup />
              </div>
              <div className="mt-12 flex lg:relative overflow-hidden">
                <HeroImage />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Separator className="-mt-44 lg:-mt-64 pb-16" />
      <Section
        title="Guides"
        subtitle="These are tutorials or articles that explain a topic from start to finish."
      >
        <Guides posts={guides?.map((guide) => convertPostNodeToPost(guide))} />
      </Section>
      <ConnectionLine className="bg-slate-900" />
      <Section title="Latest articles" subtitle="" className="lg:-mb-12">
        <Latest posts={latestPosts?.map((guide) => convertPostNodeToPost(guide))} />
      </Section>
      <Separator inverted />
      <AboutMe />
    </Layout>
  );
}

export const pageQuery = graphql`
  query MainPageQuery($currentDate: Date) {
    popularPosts: allMdx(
      filter: { frontmatter: { internaltags: { in: "popular" }, date: { lte: $currentDate } } }
      sort: { fields: [fields___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          ...postNodeFragment
        }
      }
    }
    latestPosts: allMdx(
      filter: { frontmatter: { date: { lte: $currentDate }, category: { ne: "Guide" } } }
      limit: 4
      sort: { fields: [fields___date], order: DESC }
    ) {
      nodes {
        ...postNodeFragment
      }
    }
    guides: allMdx(
      filter: { frontmatter: { category: { eq: "Guide" } } }
      limit: 3
      sort: { fields: [fields___date], order: DESC }
    ) {
      nodes {
        fields {
          slug
          date
        }
        ...postNodeFragment
      }
    }
  }
`;
