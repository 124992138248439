import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { IPost } from "../../../dto/IPost";

export const PostCard = ({ post }: { post: IPost }) => {
  return (
    <div className="box-border flex ring-transparent hover:ring-blue-500 border-solid ring-2 rounded-xl transition duration-300 ease-in-out">
      <Link
        to={post.path}
        key={`landing-guide-${post.path}`}
        className="m-1 flex-grow flex-col flex-1 flex rounded-lg shadow-lg overflow-hidden"
      >
        <div className="flex-shrink-0">
          <GatsbyImage
            className="h-48 w-full object-cover rounded-t-lg overflow-hidden isolate"
            image={getImage(post.cover.childImageSharp)}
            alt=""
          />
        </div>
        <div className="flex-1 bg-slate-800 p-6 flex flex-col justify-between">
          <div className="flex-1">
            <div className="block mt-2">
              <p className="text-xl font-semibold text-white">{post.title}</p>
              <p className="mt-3 text-base text-gray-300">{post.excerpt}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
