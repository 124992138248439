import { Link } from 'gatsby';
import React from 'react';
import { PostListing } from '../../../components/PostListing';
import { IPost } from '../../../dto/IPost';

export const Latest = ({ posts }: { posts: IPost[] }) => {
  return (
    <div className="mt-12 mx-auto max-w-md px-4 flex flex-col gap-4 sm:max-w-lg sm:px-6 lg:px-8 lg:max-w-7xl">
      {posts?.map((post) => (
        <PostListing post={post} />
      ))}
      <div className="mt-4 flex">
        <Link
          to="/blog/"
          className="block ring-2 ring-slate-800 m-auto py-3 px-8 rounded-md shadow text-white font-medium hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
        >
          See all
        </Link>
      </div>
    </div>
  );
};
